<template>
    <div class="accountPage">
        <div class="title">个人信息</div>
        <div class="avatar-box">
            <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                name="file"
                :accept="'.jpg,.jpeg,.png'"
                :headers="imageHeader"
                :http-request="handleTxCustomUpload"
                :before-upload="beforeAvatarUpload"
            >
                <img v-if="$store.state.user.userInfo.avatar" :src="avatarUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <el-button class="upload-btn" size="small" type="primary">修改头像</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
        </div>
        <div class="nickname-box">
            <div class="nick-title">昵称</div>
            <input type="text" class="nickname" :class="isNicknameEdit?'':'hide'" placeholder="请输入昵称"
                   v-model="newNickname">
            <span class="nick-label" :class="isNicknameEdit?'hide':''">{{ nickname }}</span>
            <div class="edit-btn" @click="editNickName" :class="isNicknameEdit?'hide':''">修改</div>
            <div class="edit-btn" :class="isNicknameEdit?'':'hide'">
                <span @click="ConfirmEditNickName" style="margin-right: 10px">确认</span>
                <span @click="CancelEditNickName">取消</span>
            </div>
        </div>
        <div class="title">安全隐私</div>
        <div class="edit-account">
            <div>账户密码</div>
            <div class="edit-btn" @click="changePassword">修改</div>
        </div>
        <div class="address">
            <div class="title">地址管理</div>
            <div class="add-address" @click="addAddress">新增地址</div>
        </div>
        <div class="address-list">
            <div class="address-item" v-for="item in addressList" :key="item.id">
                <div class="left">
                    <div class="name">{{ item.name }}</div>
                    <div class="mobile">{{ item.mobile }}</div>
                    <div class="address">{{ item.district }}{{ item.address }}</div>
                </div>
                <div class="right">
                    <div class="isDefault default-address" v-if="item.is_default">默认地址</div>
                    <div class="isDefault" v-else @click="changeDefault(item)">设为默认</div>
                    <div class="edit" @click="editAddress(item)">修改</div>
                    <div class="divided"></div>
                    <div class="delete" @click="deleteAddress(item.id)">删除</div>
                </div>
            </div>
        </div>
        <el-dialog
            title="修改密码"
            :visible.sync="dialogVisible"
            width="350px"
            height="400px"
        >
            <div class="edit-password">
                <div class="old-password title">原密码</div>
                <input type="password" placeholder="请输入原密码" v-model="oldPassword" class="input old-input">
                <div class="title">新密码</div>
                <input type="password" placeholder="请输入新密码" @blur.prevent="checkFirstPassword()" v-model="firstTime"
                       class="firstTime input">
                <input type="password" placeholder="请再次输入新密码" @blur.prevent="checkPassword()" v-model="secondTime"
                       class="secondTime input">
                <el-button type="primary" @click="editPassword" class="password-button" size="mini">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="addressDialogTitle"
            :visible.sync="addressDialog"
            width="350px"
        >
            <Address @closeDialog="closeDialog" :editAddressData="editAddressData" :dialogType="dialogType"></Address>
        </el-dialog>
    </div>
</template>

<script>
import store from '@/store'
import * as Api from '@/api/user/user'
import cos, { BucketMaps, Region } from '@/utils/cosClient'
import Address from '@/components/Address/Address'

export default {
    name: 'Account',
    data () {
        return {
            isNicknameEdit: false,
            newNickname: '',
            dialogVisible: false,
            oldPassword: '',
            firstTime: '',
            secondTime: '',
            addressList: '',
            addressDialog: false,
            editAddressData: {},
            addressDialogTitle: '新增地址',
            dialogType: 1,
            uploadUrl: '',
            passwrodReg: /^(?=.*[0-9])(?=.*[a-zA-Z!@_#$%^&*()\-+=,.?]).{8,32}$/,
            FileName:'',
            FileSize:0,
            txUploadUrl:'',
            txBackViewUrl:'',
            currentFile:null,
            Cos:{},
            loading:null
        }
    },
    mounted () {
        this.getAddressList()
    },
    components: {
        Address
    },
    computed: {
        avatarUrl () {
            return store.state.user.userInfo.avatar
        },
        nickname () {
            return store.state.user.userInfo.nickname
        },
        imageHeader () {
            return {Authorization: store.state.user.authorization.token_type + ' ' + store.state.user.authorization.access_token}
        }
    },
    methods: {
        handleAvatarSuccess (res) {
            
            Api.editAvatar('PUT', {
                avatar: this.Cos.uploadTxToken.url
            },res=>{
                this.loading.close()
                this.$store.dispatch('setUserAvatar', {avatar: res.data.avatar})
                this.$message.success('头像图片修改成功!')
            }, err=>{
                this.loading.close()
            })
            
        },
        async beforeAvatarUpload (file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 jpn/png 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            const { name, size } = file
            this.FileName = name
            this.FileSize = size
            return isJPG && isLt2M
        },
        handleTxCustomUpload(e) {
            const file = e.file;
            this.currentFile = file
            this.handleBeginOrContinue()
        },
        async handleBeginOrContinue() {

            // loading.value = true
            // isPause.value = false
            // const type = props.isCdn ? 'cdn' : props.fileType
            const type = 'cdn'
            const Bucket = BucketMaps[type]
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            this.Cos = cos()
            await this.Cos.getToken('cdn', {
                ext:  this.currentFile.type.split('/')[1]
            }).catch(err=>{
                this.loading.close()
            })
            

            const UploadUrl = this.Cos.uploadTxToken.url?.match(
                /https?:\/\/[^/]+\/(.+)/
            )[1]
            this.Cos.uploadFile(
            {
                Bucket,
                Region,
                Key: UploadUrl,
                Body: this.currentFile,
                SliceSize: 1024 * 1024 * 5
            },
            (err, data) => {
                if (err) {
                    console.log('上传失败', err);
                    this.loading.close()
                } else {
                    console.log('上传成功');
                    this.handleAvatarSuccess({
                        data:{
                            avatar: this.Cos.uploadTxToken.url
                        }
                    })
                }
            }
            )
        },
        editNickName () {
            this.isNicknameEdit = true
        },
        ConfirmEditNickName () {
            let params = {
                nickname: this.newNickname
            }
            Api.profile('put', params, () => {
                this.$store.dispatch('setUserNickname', {nickname: this.newNickname})
                this.isNicknameEdit = false
            }, '')
        },
        CancelEditNickName () {
            this.isNicknameEdit = false
        },
        changePassword () {
            this.dialogVisible = true
        },
        editPassword () {
            let params = {
                password: this.oldPassword,
                new_password: this.secondTime
            }
            Api.resetPasswored(params, () => {
                this.$message.success('修改成功')
                this.dialogVisible = false
            })
        },
        getAddressList () {
            Api.address('get', '', res => {
                this.addressList = res
            })
        },
        addAddress () {
            this.dialogType = 1
            this.addressDialogTitle = '新增地址'
            this.addressDialog = true
            this.editAddressData = {}
        },
        editAddress (data) {
            this.dialogType = 2
            this.addressDialogTitle = '修改地址'
            this.editAddressData = data
            this.addressDialog = true
        },
        deleteAddress (id) {
            Api.address('delete', '', () => {
                this.$message.success('删除成功')
                this.getAddressList()
            }, '', id)
        },
        changeDefault (item) {
            let params = {...item}
            params.is_default = 1
            Api.address('put', params, () => {
                this.$message.success('修改成功')
                this.getAddressList()
            }, '', item.id)
        },
        closeDialog () {
            this.addressDialog = false
            this.getAddressList()
        },
        checkFirstPassword () {
            let value = this.firstTime
            if (value === '') {
                this.$message.error('密码不能为空')
            } else if (value.length < 8) {
                this.$message.error('密码长度不能小于8位')
            } else {
                if (!this.passwrodReg.test(value)) {
                    this.$message.error('密码必须包含数字，且必须包含字母或其它符号（!@_#$%^&*()-+=,.?）')
                } else {
                }
            }
        },
        checkPassword () {
            if (this.firstTime === this.secondTime) {
                return
            } else {
                this.$message.error('两次密码不一致，请重新输入')
                this.firstTime = ''
                this.secondTime = ''
            }
        }
    }
}
</script>

<style lang="less">
/*@import '../../../assets/style/lib-base';*/

.accountPage {
    padding: 30px;

    & > .title {
        width: 100%;
        height: 70px;
        font-size: @fontSizeSpTitle;
        font-weight: bold;
        color: #333;
        text-align: left;
        line-height: 70px;
        border-bottom: 1px solid #e6e6e6;
        padding-left: 10px;
    }

    & > .avatar-box {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px 9px 15px 20px;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #e6e6e6;


        & > .avatar-uploader {
            width: 64px;
            height: 64px;

            .el-upload {
                border: 1px dashed #d9d9d9;
                box-sizing: border-box;
            }

        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 64px;
            height: 64px;
            line-height: 64px;
            text-align: center;
        }

        .avatar {
            width: 64px;
            height: 64px;
            display: block;

            & > img {

            }
        }

        .upload-btn {
            font-size: @fontSizeSubTitle;
            color: @papaGreen;
            position: absolute;
            right: 0;
            top: 32px;
            background: #fff;
            border: none;
        }

        .el-upload__tip {
            position: absolute;
            right: 0;
            top: 56px;
        }
    }

    & > .nickname-box {
        width: 100%;
        padding: 20px 10px;
        display: flex;
        position: relative;

        & > .nick-title {
            margin-right: 56px;
        }

        & > .nickname {
            border: none;
            outline: none;
            font-size: @fontSizeSubTitle;
            color: #999999;
        }

        & > .nick-label {
            font-size: @fontSizeSubTitle;
            color: #999999;
            line-height: 23px;
        }

        & > .hide {
            display: none;
        }

        & > .edit-btn {
            position: absolute;
            right: 36px;
            color: @papaGreen;
            font-size: @fontSizeSubTitle;
            font-weight: 500;
            cursor: pointer;
        }
    }

    & > .edit-account {
        width: 100%;
        padding: 20px 10px;
        display: flex;
        position: relative;

        & > .edit-btn {
            cursor: pointer;
            position: absolute;
            right: 36px;
            color: @papaGreen;
            font-size: @fontSizeSubTitle;
            font-weight: 500;
        }
    }

    .edit-password {
        display: flex;
        flex-direction: column;

        & > .title {
            font-size: @fontSizeSubTitle;
            color: #333;
            text-align: left;
            margin-bottom: 20px;
        }

        & > .input {
            width: 100%;
            height: 43px;
            border-bottom: 1px solid #e6e6e6;
        }

        & > .old-input {
            margin-bottom: 20px;
        }

        & > .password-button {
            width: 160px;
            height: 40px;
            align-items: center;
            margin: 20px auto 0;

        }

    }

    & > .address {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        padding-left: 10px;
        height: 70px;
        margin-bottom: 14px;

        & > .title {
            font-size: @fontSizeSpTitle;
            font-weight: bold;
            color: #333;
            text-align: left;
        }

        & > .add-address {
            color: @papaGreen;
            font-size: 14px;
            padding-right: 25px;
            cursor: pointer;
        }
    }

    & > .address-list {
        display: flex;
        flex-direction: column;

        & > .address-item {
            padding-left: 20px;
            box-sizing: border-box;
            display: flex;
            width: 100%;
            height: 26px;
            font-size: 14px;
            color: #555;
            line-height: 26px;
            justify-content: space-between;
            margin-bottom: 10px;

            & > .left {
                display: flex;

                & > .name, .mobile {
                    margin-right: .5em;
                }
            }

            & > .right {
                display: flex;
                align-items: center;

                & > .isDefault {
                    margin-right: 26px;
                    padding: 0 5px;
                    cursor: pointer;
                }

                & > .divided {
                    margin: 0 10px;
                    width: 1px;
                    height: 14px;
                    background: #555;
                }

                & > .default-address {
                    background: #F4FDF8;
                    color: @papaGreen;
                    cursor: pointer;
                }

                & > .edit, .delete {
                    cursor: pointer;
                }
            }
        }
    }

}
</style>
