import COS from 'cos-js-sdk-v5'
import { tencentToken } from '@/api/index/index'

export const BucketMaps = {
  image: 'papaedu-image-1317401405',
  audio: 'papaedu-audio-1317401405',
  video: 'papaedu-video-1317401405',
  file: 'papaedu-file-1317401405',
  cdn: 'papaedu-cdn-1317401405'
}
export const Region = 'ap-shanghai'

function cos() {
  const Cos = new COS({
    async getAuthorization(_, callback) {
      // 过期时间
      const HOUR = 1
      const currentTime = new Date().getTime()
      const StartTime = Math.floor(currentTime / 1000)
      const expirationTime = new Date(currentTime + HOUR * 60 * 60 * 1000)
      const ExpiredTime = Math.floor(expirationTime.getTime() / 1000)
      callback({
        TmpSecretId: Cos.uploadTxToken?.tmp_secret_id || '',
        TmpSecretKey: Cos.uploadTxToken?.tmp_secret_key || '',
        SecurityToken: Cos.uploadTxToken?.token,
        StartTime,
        ExpiredTime
      })
    }
  })
  // type: 'cdn' | 'image'
  Cos.getToken = async (type, params, errorFn) => {
    return new Promise((resolve, reject) => {
      tencentToken(type, params, res => {
        Cos.uploadTxToken = res
        resolve();
      }, errorFn => {
        reject(errorFn);
      });
    });
  }

  return Cos
}

export default cos
